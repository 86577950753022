import CourseCard from "./course-card";
import {Link} from "gatsby";
import Icon from "./icon";
import RightArrow from "./right-arrow";
// @ts-ignore
import React from "react";

function CoursePreview({course, courseIndex, firstCourse}) {
    return <li>
        <article className={"flex flex-col gap-13 md:flex-row " + (courseIndex > 0 ? "border-t-2 border-dashed border-t-sky-900 pt-13" : "")}>
            <CourseCard
                title={course.title}
                duration={course.duration}
                difficulty={course.difficulty}
                backgroundColor={course.backgroundColor}
                slug={course.slug}
                icon={course.icon}
                type={course.type}
                top={course.top}
            />
            <section className="flex-1">
                <div className="hidden lg:flex flex-col gap-0.5 md:gap-2 md:flex-row">
                    {course.tags ? course.tags.map((tag, tagIndex) => {
                        return (
                            <div key={tagIndex} className="flex-none cursor-pointer border-2 border-gray-100 px-4 py-2 text-xxxs font-bold uppercase text-gray-500 duration-200 before:content-['#'] hover:bg-sky-900 hover:text-white">{tag}</div>
                        )
                    }) : <></>}
                </div>
                <section className="lg:mt-4">
                    <p>
                        {course.description}
                    </p>
                </section>
                <div className={"flex flex-row mt-12 " + (firstCourse ? "place-content-between" : "place-content-end" )}>
                    {firstCourse ?
                        <Link to={course.slug}>
                            <span className="flex items-center gap-x-2 text-neutral-800 sm:mt-4 md:mt-6 lg:mt-0 xl:ml-0">
                                <Icon id="calendar" size={40} />
                                <span className="flex flex-col leading-none">
                                    <span className="text-sm">Next public session</span>
                                    <span className="text-lg font-bold">{new Intl.DateTimeFormat('de-DE', {}).format(new Date(firstCourse.startDate))}</span>
                                </span>
                            </span>
                        </Link>
                        : <></>
                    }
                    <Link to={course.slug} className="flex items-center font-bold uppercase text-sm lg:text-lg gap-x-1 md:gap-x-2 hover:text-sky-900">
                        Read more
                        <RightArrow />
                    </Link>
                </div>
            </section>
        </article>
    </li>
}

export default CoursePreview